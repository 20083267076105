import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_app_select_dept = _resolveComponent("app-select-dept")

  return (_openBlock(), _createBlock(_component_app_select_dept, {
    ref: "picker",
    levelType: "onLest",
    maxParentId: 'O' + _ctx.authUser.orgParentId + 'D-1',
    selectItem: _ctx.requestItem,
    selectFunction: _ctx.submitFunction,
    searchFieldList: _ctx.searchField,
    multiple: _ctx.multiple,
    style: {"z-index":"4000"}
  }, null, 8, ["maxParentId", "selectItem", "selectFunction", "searchFieldList", "multiple"]))
}