
import { Options } from "vue-class-component";
import ComponentMixins from "../ComponentMixins";
import DeptPickerComponent from "../../../components/DeptPicker/DeptPicker.vue";

@Options({
    name: "flowable-common-form-components-dept-picker",
    components: { DeptPicker: DeptPickerComponent },
    props: {
        placeholder: {
            type: String,
            default: "请选择部门",
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        readerMode: {
            type: Boolean,
            default: false,
        },
    },
})
export default class PostPicker extends ComponentMixins {
    public selected(values: any) {
        this._value = values;
    }
    public delDept(i: any) {
        this._value.splice(i, 1);
    }
}
